import { Box, Grid, Tab, Tabs, useTheme } from "@mui/material";
import React from "react";
import Text from "../../visuals/Text";
import { FormattedMessage } from "react-intl";
import ConsultButton from "../../reusable/ConsultButton";

const tabs = [
    "Tabs-Digital"   ,
    "Tabs-Marketing" ,
    "Tabs-Sales"     ,
    "Tabs-Data"
]

export default (props) => {
    const [tab, setValue] = React.useState(0);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    // custom tabs with proper background styling
    const tabBg = (label, border) => {
        return (<Tab 
        sx={{py: 4, px: 8}}
        label={
            <React.Fragment>
            <FormattedMessage id={label} />
            <Box
            sx={{
                position: "absolute",
                left: 0, right: 0, top: 0, bottom: 0,
                zIndex: -2,
                borderRadius: border, 
                backgroundColor: theme.palette.faded.main,}}/>
            </React.Fragment>
        }
        color="contrast"/>);
    }

    return(
    <React.Fragment>
    <Grid container my={20}>
        <Text item xs={12} align="center" variant="h2" bold id="Tabs-Title"/>

        {/* tabs */}
        <Grid item xs={12} py={10} px={{xs: 0, md: 10}} justifyContent="center">
            <Tabs
            className="tabs-custom"
            indicatorColor="dark"
            textColor="primary"
                centered
                value    = {tab}
                onChange = {handleChange}
            sx={{
                '.MuiTabs-indicator': {
                    height: "100%",
                    borderRadius: 4,
                    backgroundColor: theme.palette.dark.main,
                    zIndex: -1
                },
                '.MuiTabs-flexContainer': {
                    color: theme.palette.faded.contrastText
                },
                '.MuiTab-root.Mui-selected': {
                }
            }}
            >
                {tabBg(tabs[0], "16px 0 0 16px")}
                {tabBg(tabs[1], "0")}
                {tabBg(tabs[2], "0")}
                {tabBg(tabs[3], "0 16px 16px 0")}
            </Tabs>
        </Grid>

        {/* content */}
        <Grid container item xs={12} p={8} py={{xs: 2, md: 8}}>
            <Text color="primary" item xs={12} md={6} variant="h3" bold id={tabs[tab]+"-Short"} pr={{xs: 0, md: 18}} hl="secondary" />
            <Grid container item xs={12} md={6}>
              <Text color="primary" item xs={12} variant="body" id={tabs[tab]+"-Long"}/>
              <ConsultButton sx={{marginTop: 6}}/>
            </Grid>
        </Grid>
    </Grid>       
    </React.Fragment>
    );
}