import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../sections/layout/Navbar";
import { Grid, ThemeProvider } from "@mui/material";
import DefaultTheme from "../../themes/DefaultTheme";
import Footer from "../sections/layout/Footer";
import MailList from "../sections/layout/MailList";
import BackgroundAnimation from "../../functions/BackgroundAnimation";
import GetScrollSpeed from "../../functions/GetScrollSpeed";
import PageBackground from "../visuals/PageBackground";

function Layout() {
    const [fade, setFade] = useState(0);

    function renderFade() {
        setTimeout(() => {
            setFade(localStorage.getItem("fade"));
            renderFade();
        }, 30);
    }

    useEffect(() => {
        renderFade();
    });

    return (
      <React.Fragment>
        <BackgroundAnimation/>
        <GetScrollSpeed/>
        <PageBackground/>
        <ThemeProvider theme={DefaultTheme(fade)}>
        <header id="navbar">
            <Navbar/>
        </header>
        <Grid container px={{xs: 0, sm: 10, md: 25, lg: 50, xl: 72}}>
            <Outlet/>
        </Grid>
        <section><MailList/></section>
        <footer>
            <Footer/>
        </footer>
        </ThemeProvider>
      </React.Fragment>
    );
  }
  
  export default Layout;