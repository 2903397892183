import React from "react";
import Header from "../sections/home/Header";
import Video from "../sections/home/Video";
import Industries from "../sections/home/Industries";
import Quote from "../sections/home/Quote";
import Tabs from "../sections/home/Tabs";
import Community from "../sections/home/Community";
import Contact from "../sections/home/Contact";
import Elements from "../sections/home/Elements";
import { useTheme } from "@mui/material";

function Home() {
    const theme = useTheme();
    document.body.style.backgroundColor = theme.palette.primary.main;
    
  return (
    <React.Fragment>
    <section id="header"    > <Header    /> </section>
    {/* <section id="video"     > <Video     /> </section> */}
    <section id="services"  > <Tabs      /> </section>
    <section id="industries"> <Industries/> </section>

    {/* fade to black */}
    <section id="about"    className="DarkBgToggle"> <Elements/> </section>
    <section id="quote"    className="DarkBgToggle"> <Quote   /> </section>

    {/* fade to white */}
    <section id="community"> <Community/> </section>
    <section id="contact"  > <Contact  /> </section>
    </React.Fragment>
  );
}

export default Home;