import { useTheme } from "@mui/material";
import React, { useEffect } from "react";

// export default (props) => {
//     const [timer, setTimer] = React.useState(0);
//     function animate() {
//         // setTimeout(() => {
//         //     setTimer(timer+1);
//         //     setPosition({...position, y: timer})
//         //     animate();
//         //     console.log(timer);
//         // }, 1000);
//     }
//     useEffect(() => {
//         animate();
//     });


//     const [position, setPosition] = React.useState({
//         x: 0,
//         y: 0
//     });
//     const theme = useTheme();
//     const color =
//     props.color ?
//     theme.palette[props.color]? theme.palette[props.color].main : props.color
//     :
//     theme.palette.secondary.main;

//     return(<div
//     className="blob"
//     style={{
//         opacity: props.opacity ?? "0.3",
//         top: props.top + position.y + "px",
//         left: props.left + position.x + "vw",
//         position: "absolute",
//         zIndex: "-10",
//         background: "radial-Gradient(" + color + ", transparent 75%)",
//         width: props.size? props.size * 20 + "%" : "20%",
//         maxWidth: "600px",
//         minWidth: "200px",
//         aspectRatio: "1 / 1"
//     }}
//     ></div>);
// }

export default (props) => {

    const [timer , setTimer ] = React.useState(1);
    const [random, setRandom] = React.useState({x: 0, y: 0});

    function updateTimer() {
        setTimer(timer + 1);
        setRandom({
            x: random.x + (Math.random() - 0.5)*0.6,
            y: random.y + (Math.random() - 0.5)*0.6
        });
        setPosition({...position, 
            y: props.top  + Math.sin(timer * 0.05) * random.y + Math.min(Math.max(localStorage.getItem("speed") * -5, -10), 10), 
            x: props.left + Math.cos(timer * 0.05) * random.x});
    }

    useEffect(() => {
        const animate = setInterval(() => {
            updateTimer();
        }, 100);
        return () => {clearInterval(animate)};
    });


    const [position, setPosition] = React.useState({
        x: 0,
        y: 0
    });
    const theme = useTheme();
    const color =
    props.color ?
    props.color
    :
    theme.palette.secondary.main;

    return(<div
    className="blob"
    style={{
        transition: "all 0.5s",
        opacity: props.opacity ?? "0.3",
        top: position.y + "%",
        left: position.x + "%",
        position: "relative",
        zIndex: "-50",
        background: "radial-Gradient(" + color + ", transparent 75%)",
        width: props.size? props.size * 20 + "%" : "20%",
        maxWidth: "600px",
        maxHeight: "600px",
        minWidth: "200px",
        aspectRatio: "1 / 1"
    }}
    ></div>);
}