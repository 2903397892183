import Accordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

export default styled(Accordion)(({ theme }) => {
  return {
    boxShadow: 'none',
    backgroundColor: "transparent",
    border: "none",
    color: theme.palette.primary.contrastText,
    '.MuiAccordionDetails-root': {
        '.MuiTypography-body': {
            fontWeight: theme.typography.fontWeightRegular
        }
    },
    '.MuiAccordionSummary-root': {
        opacity: 0.6,
        '.MuiSvgIcon-root': {
            fill: theme.palette.primary.contrastText,
        }
    },
    '.MuiAccordionSummary-root.Mui-expanded': {
        opacity: 1
    },
  };
});