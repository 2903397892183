import React from "react"
import Text from "../../visuals/Text"
import { Grid } from "@mui/material"
// TODO: color

export default (props) => {
    return(
    <React.Fragment>
        <Text className="quote-grad" container fontFamily='Inter' variant="h2" bold align="center" id="Quote" my={10} px={{xs: 5, md: 0}}/>
    </React.Fragment>
    );
}