import { useEffect } from "react";

export default () => {
  var checkScrollSpeed = (function(settings){
      settings = settings || {};
    
      var lastPos, newPos, timer, delta, 
          delay = settings.delay || 50; // in "ms" (higher means lower fidelity )
    
      function clear() {
        lastPos = null;
        delta = 0;
      }
    
      clear();
      
      return function(){
        newPos = window.scrollY;
        if ( lastPos != null ){ // && newPos < maxScroll 
          delta = newPos -  lastPos;
        }
        lastPos = newPos;
        clearTimeout(timer);
        timer = setTimeout(clear, delay);
        return delta;
      };
  })();

  useEffect(() => {
    window.onscroll = function(){
      localStorage.setItem( "speed", checkScrollSpeed() );
    };

    // window.onmousemove = (event) => {
    //   localStorage.setItem( "px", event.clientX );
    //   localStorage.setItem( "py", event.clientY );
    // };
  })
}