import { AccordionDetails, AccordionSummary, Grid, Hidden, Tab, Tabs, useTheme } from "@mui/material";
import React from "react";
import Text from "../../visuals/Text";
import {ReactComponent as Disc} from '../../visuals/graphics/disc.svg';
import Accordion from "../../custom/Accordion";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const tabs = [
    "Community-Customer"   ,
    "Community-Ambassador" ,
    "Community-Consultant" ,
    "Community-Energyzed"
]

export default (props) => {
    const [tab, setValue] = React.useState(0);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const tabContent = (id) => {
        return(
            <React.Fragment>
                <Grid container flex justifyContent="start" gap={3}>
                    <Disc style={{alignSelf: "center"}}/>
                    <Text variant="h2" fontFamily="Inter" bold id={id}/>
                </Grid>
            </React.Fragment>
        );
    };

    const accordionContent = (id) => {
        return(
            <Accordion>
                <AccordionSummary id={id+"-header"} aria-controls="panel-content" expandIcon={<ArrowDropDownIcon/>}>
                    <Grid container flex justifyContent="start" gap={3}>
                        <Disc style={{alignSelf: "center", scale: "0.7"}}/>
                        <Text color="primary" variant="h3" bold id={id} />
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Text color="primary" variant="body" id={id+"-Long"} align="left" pb={5}/>
                </AccordionDetails>
            </Accordion>
        );
    };

    return(
    <React.Fragment>

    {/* mobile version */}
    <Hidden mdUp>
        <Grid container px={5} mb={15}>
            <Text item xs={12} align="center" variant="h2" bold id="Community-Title" hl="secondary" mb={2}/>
            <Grid item xs={12}>
                <Tab label={accordionContent(tabs[0])} />
                <hr style={{opacity: 0.5, margin: 0}}/>
                <Tab label={accordionContent(tabs[1])} />
                <hr style={{opacity: 0.5, margin: 0}}/>
                <Tab label={accordionContent(tabs[2])} />
                <hr style={{opacity: 0.5, margin: 0}}/>
                <Tab label={accordionContent(tabs[3])} />
            </Grid>
        </Grid>
    </Hidden>

    {/* desktop version */}
    <Hidden mdDown>
    <Grid container my={20}>
        <Text item xs={12} align="center" variant="h2" bold id="Community-Title" hl="secondary" mb={8}/>

        {/* tabs */}
        <Grid item md={5} xl={4} justifyContent="center">
            <Tabs
            orientation="vertical"
            indicatorColor="secondary"
            textColor="dark"
            centered
            value    = {tab}
            onChange = {handleChange}
            TabIndicatorProps={{
                style: { display: 'none' }
              }}
            sx={{
                width: "100%",
                '.MuiTabs-flexContainer h2': {
                    color: theme.palette.dark.main + "55"
                },
                '.MuiTab-root.Mui-selected h2': {
                    color: theme.palette.dark.main
                },
                '.MuiTabs-flexContainer svg': {
                    fill: theme.palette.dark.main + "55"
                },
                '.MuiTab-root.Mui-selected svg': {
                    fill: theme.palette.dark.main
                },
                'button': {
                    minWidth: "100%"
                }
            }}
            >
                <Tab disableRipple label={tabContent(tabs[0])} />
                <Tab disableRipple label={tabContent(tabs[1])} />
                <Tab disableRipple label={tabContent(tabs[2])} />
                <Tab disableRipple label={tabContent(tabs[3])} />
            </Tabs>
        </Grid>

        {/* content */}
        <Grid container item md={7} xl={8} p={8} gap={3}>
            <Text color="primary" item xs={12} variant="h3" bold id={tabs[tab]+"-Short"} pr={{xs: 0, md: 18}}/>
            <Text color="primary" item xs={12} variant="body" id={tabs[tab]+"-Long"}/>
        </Grid>
    </Grid>       
    </Hidden>
    </React.Fragment>
    );
}