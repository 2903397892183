import { Grid, Hidden } from "@mui/material";
import { useEffect, useState } from "react";
import BlurryCircle from "./BlurryCircle";

export default (props) => {
    function update() {
        setPosition(window.scrollY * -0.25);
    }

    useEffect(() => {
        const animate = setInterval(() => {
            update();
        }, 100);
        return () => {clearInterval(animate)};
    });


    const [position, setPosition] = useState(0);

    return(
    <Grid container sx={{
        pointerEvents: "none",
        overflow: "hidden",
        position: "fixed",
        left: 0, top: 0, bottom: 0,
        width: "100vw"
    }}>
        <Grid container sx={{
            pointerEvents: "none",
            transition: "all 0.3s",
            position: "relative",
            left: 0, right: 0, top: position,
            height: "250vh"
        }}>
            {/* bg elements */}
            <BlurryCircle size={6} left={30}/>
            {/* one makes mobile look good but larger screens look icky so there's different bgs at different breakpoints */}
            <Hidden lgUp>
                <BlurryCircle size={6} left={-10} color="#48CC96" top={-20}/>
                <BlurryCircle size={2} left={-60} top={28}/>
                <BlurryCircle size={2} left={-30} top={0}/>
                <BlurryCircle size={2} left={60} color="#48CC96" top={5}/>
                <BlurryCircle size={2} left={50} color="#48CC96" top={10}/>
            </Hidden>
            <Hidden lgDown>
                <BlurryCircle size={6} left={10} color="#48CC96" top={1}/>
                <BlurryCircle size={2} left={-60} top={30}/>
                <BlurryCircle size={2} left={60} color="#48CC96" top={17}/>
                <BlurryCircle size={2} left={50} color="#48CC96" top={-10}/>
            </Hidden>

        </Grid>
    </Grid>
    );
}