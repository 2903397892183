import { AccordionDetails, AccordionSummary, Button, CircularProgress, Grid, Hidden, Tab, Tabs, useTheme } from "@mui/material";
import React from "react";
import Text from "../../visuals/Text";
import {ReactComponent as Logo} from '../../visuals/graphics/LogoWheel.svg';
import {ReactComponent as Logo_} from '../../visuals/graphics/TextWheel.svg';
import { FormattedMessage } from "react-intl";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Accordion from "../../custom/Accordion";

const tabs = [
    "Elements-Community"   ,
    "Elements-Psychology"  ,
    "Elements-Solopreneur" ,
    "Elements-Managers"    ,
    "Elements-AI"          ,
    "Elements-Domain"      ,
    "Elements-Flexible"    ,
    "Elements-LongTerm"
]

const circle = (color, value, dynamic = false) => {
    return(
        <CircularProgress className="wheel" thickness={7} variant="determinate" value={!dynamic? 100 : 100/tabs.length} color={color} sx={{
            'svg': {
                transition: "transform 1s ease-in-out",
                transform: "rotate("+ value/tabs.length * 360 +"deg)",
            },
            opacity: !dynamic ? 0.2 : 1,
            position: "absolute",
            right: "55%",
            minWidth: "513px",
            width: "60% !important"
        }}/>
    );
}

export default (props) => {
    const [tab, setValue] = React.useState(0);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const tabContent = (id) => {
        return(<Text variant="h5" bold id={id} alignSelf="baseline" my={{md:1, xl:2}}/>);
    };

    const accordionContent = (id) => {
        return(
            <Accordion>
                <AccordionSummary id={id+"-header"} aria-controls="panel-content" expandIcon={<ArrowDropDownIcon/>}>
                    <Text color="primary" variant="h3" bold id={id} />
                </AccordionSummary>
                <AccordionDetails>
                    <Text color="primary" variant="body" id={id+"-Text"} align="left" pb={5}/>
                </AccordionDetails>
            </Accordion>
        );
    };

    return(
    <React.Fragment>
    {/* mobile version */}
    <Hidden mdUp>
        <Grid container px={5}>
            <Text item xs={12} align="center" variant="h2" bold id="Elements-Title" hl="contrast" mb={2}/>
            <Grid item xs={12}>
                <Tab label={accordionContent(tabs[0])} />
                <hr style={{opacity: 0.5, margin: 0}}/>
                <Tab label={accordionContent(tabs[1])} />
                <hr style={{opacity: 0.5, margin: 0}}/>
                <Tab label={accordionContent(tabs[2])} />
                <hr style={{opacity: 0.5, margin: 0}}/>
                <Tab label={accordionContent(tabs[3])} />
                <hr style={{opacity: 0.5, margin: 0}}/>
                <Tab label={accordionContent(tabs[4])} />
                <hr style={{opacity: 0.5, margin: 0}}/>
                <Tab label={accordionContent(tabs[5])} />
                <hr style={{opacity: 0.5, margin: 0}}/>
                <Tab label={accordionContent(tabs[6])} />
                <hr style={{opacity: 0.5, margin: 0}}/>
                <Tab label={accordionContent(tabs[7])} />
            </Grid>
        </Grid>
    </Hidden>

    {/* desktop version */}
    <Hidden mdDown>
    <Grid container my={20}>
        <Text item xs={12} align="center" variant="h2" bold id="Elements-Title" hl="contrast" mb={12} px={{xs: 2, md: 0}}/>

        {/* content */}
        <Grid container item md={8} lg={9} p={8} gap={3} pr={30}>
            {/* wheel */}
            <Grid p={50} 
            pl={{md: 80, xl: 170}} 
            mb={{xs: 10, md: 20, xl: 35}} position="relative" textAlign="center" justifyContent="center" alignItems="center" flex
            pt={{xs: 10, md: 30, xl:50}}>
                {circle("dark"     , -1 )}
                {circle("contrast" , tab , true)}
                <Logo className="wheel1" style={{
                    fill: theme.palette.dark.main,
                    position: "absolute",
                    marginLeft: "-390px",
                    marginTop: "-70px"
                    }}/>
                <Logo_ className="wheel2" style={{
                    fill: theme.palette.dark.main,
                    position: "absolute",
                    marginLeft: "-325px",
                    marginTop: "-10px"
                }}/>
            </Grid>

            {/* info */}
            <Text color="primary" item xs={12} variant="h3" bold id={tabs[tab]} pr={{xs: 0, md: 18}}/>
            <Text color="primary" item xs={12} variant="body" id={tabs[tab]+"-Text"}/>
            <Button variant="contained" color="dark" sx={{padding: "16px 24px", marginTop: 6}}
            onClick={() => document.getElementById("contact").scrollIntoView()}>
                <FormattedMessage id="Elements-Button"/>
            </Button> 
        </Grid>


        {/* tabs */}
        <Grid item md={4} lg={3} justifyContent="center">
            <Tabs
            orientation="vertical"
            indicatorColor="secondary"
            centered
            value    = {tab}
            onChange = {handleChange}
            TabIndicatorProps={{
                style: { display: 'none' }
              }}
            sx={{
                '.MuiTabs-flexContainer h2': {
                    color: theme.palette.dark.main + "55"
                },
                '.MuiTab-root.Mui-selected h2': {
                    color: theme.palette.dark.main,
                },
                '.MuiTab-root.Mui-selected': {
                    background: theme.palette.dark.main + "22",
                    borderRadius: 2
                }
            }}
            >
                <Tab label={tabContent(tabs[0])} />
                <Tab label={tabContent(tabs[1])} />
                <Tab label={tabContent(tabs[2])} />
                <Tab label={tabContent(tabs[3])} />
                <Tab label={tabContent(tabs[4])} />
                <Tab label={tabContent(tabs[5])} />
                <Tab label={tabContent(tabs[6])} />
                <Tab label={tabContent(tabs[7])} />
            </Tabs>
        </Grid>
    </Grid>
    </Hidden>  
    </React.Fragment>
    );
}