import React from "react";
import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";

export default (props) => {
    return(
    <Button variant="contained" color="secondary" sx={{padding: "16px 24px", ...props.sx}}
    onClick={() => document.getElementById("contact").scrollIntoView()}>
        <FormattedMessage id="Consult-Button"/>
    </Button> 
    );}