import React, { useEffect, useState } from "react";
import Text from "../../visuals/Text";
import { Grid, Hidden } from "@mui/material";
import {ReactComponent as WireFrameGrid} from '../../visuals/graphics/WireFrameGrid.svg';
import BlurryCircle from "../../visuals/BlurryCircle";
import {ReactComponent as Instagram} from '../../visuals/logos/instagram.svg';
import {ReactComponent as Linkedin} from '../../visuals/logos/linkedin.svg';
import ConsultButton from "../../reusable/ConsultButton";
import { useTheme } from "@emotion/react";
import { FormattedMessage } from "react-intl";

export default () => {
  const [loaded, setLoaded] = useState(false);
  const [effectState, setEffect] = useState("0px");
  const theme = useTheme();

  useEffect(() => {
    setLoaded(true);
    if(loaded)
      setEffect("100%");
  });

  return (
    <React.Fragment>
        <Grid container justifyContent="center" mt={{xs: 35, md: 40, xl: 65}} mb={20} position="relative"  px={{xs: 5, md: 0}}>
            {/* This snipper is consistent and concise but requires a new line */}
            {/* <Text item xs={12} variant="h1" id="Header-Text" align="center" zIndex={2} values={{placeholder: 
              <Text className="header-effect" variant="h1" color="dark" 
                sx={{
                  textAlign: "-webkit-center",
                  'h1' : {
                    borderRadius: 2,
                    backgroundColor: "black",
                    width: "fit-content",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  },
                  width: effectState}} 
                id="Header-Placeholder"/>
            }}/> */}
            <Text item xs={12} variant="h1" id="Header-Text" align="center" zIndex={2} sx={{display: {xs: "none" , md: "block"}}} values={{br: <br/>, placeholder: 
              <span className="header-effect"
                style={{
                  position: "relative",
                  textAlign: "-webkit-center",
                  borderRadius: 2,
                  color: theme.palette.dark.contrastText,
                  paddingLeft: "15px",
                  paddingRight: "15px"}} >
                    <div style={{
                      position: "absolute", top: 0, bottom: 0, left: 0,
                      zIndex: -1,
                      float: "left",
                      borderRadius: 2,
                      backgroundColor: theme.palette.dark.main,
                      color: theme.palette.dark.contrastText,
                      width: effectState
                    }}></div>
                    <FormattedMessage id="Header-Placeholder"/>
              </span>
            }}/>

            <Text item xs={12} variant="h1" id="Header-Text-Mobile" align="center" zIndex={2} sx={{display: {xs: "block" , md: "none"}}} values={{br: <br/>, placeholder: 
              <span>
                <span className="header-effect"
                style={{
                  position: "relative",
                  textAlign: "-webkit-center",
                  borderRadius: 2,
                  color: theme.palette.dark.contrastText,
                  paddingLeft: "15px",
                  paddingRight: "15px"}} >
                    <div style={{
                      position: "absolute", top: 0, bottom: 0, left: 0,
                      zIndex: -1,
                      float: "left",
                      borderRadius: 2,
                      backgroundColor: theme.palette.dark.main,
                      color: theme.palette.dark.contrastText,
                      width: effectState
                    }}></div>
                    <FormattedMessage id="Header-Placeholder-Mobile1"/>
                </span>
                <br/>
                <span className="header-effect"
                style={{
                  margin: "10px",
                  position: "relative",
                  textAlign: "-webkit-center",
                  borderRadius: 2,
                  color: theme.palette.dark.contrastText,
                  paddingLeft: "15px",
                  paddingRight: "15px"}} >
                    <div style={{
                      position: "absolute", top: 0, bottom: 0, left: 0,
                      zIndex: -1,
                      float: "left",
                      borderRadius: 2,
                      backgroundColor: theme.palette.dark.main,
                      color: theme.palette.dark.contrastText,
                      width: effectState
                    }}></div>
                    <FormattedMessage id="Header-Placeholder-Mobile2"/>
                  </span>
              </span>
            }}/>
            <Text item xs={12} variant="h4" id="Header-Subtext" align="center" zIndex={2}/>

            <ConsultButton sx={{marginTop: "31px", zIndex: 2}}/>

            <WireFrameGrid style={{position: "absolute", zIndex: "0", top: "-200px", width: "30%"}}/>
        </Grid>

        <Hidden mdDown>
          <Grid container sx={{position: "fixed", left: "60px", top: "30%"}} rowGap={3}>
              <Grid item xs={12}><a target="_blank" href="https://instagram.com/energyzed_"><Instagram fill={theme.palette.dark.main}/></a></Grid>
              <Grid item xs={12}><a target="_blank" href="https://www.linkedin.com/company/energyzed.io" ><Linkedin fill={theme.palette.dark.main}/></a></Grid>
          </Grid>
        </Hidden>
    </React.Fragment>
  );
}