import { AppBar, Button, Grid, Hidden, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import Text from "../../visuals/Text";
import { useNavigate } from "react-router-dom";
import {ReactComponent as Instagram} from '../../visuals/logos/instagram.svg';
import {ReactComponent as Linkedin} from '../../visuals/logos/linkedin.svg';
import {ReactComponent as Logo} from '../../visuals/logos/logo.svg';
import {ReactComponent as LogoText} from '../../visuals/logos/logoText.svg';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from "react-intl";

const pages = [
    {title: 'Services'   , handle: "services"   }, 
    {title: 'Industries' , handle: "industries" }, 
    {title: 'About'      , handle: "about"      }, 
    {title: 'Contact'    , handle: "contact"    }];

function Navbar(props) {
    const [anchorElNav , setAnchorElNav ] = React.useState(null);
    const [mobileOpen  , setOpen        ] = React.useState(false);
    const navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setOpen(true);
    //   setAnchorElNav(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
        setOpen(false);
    //   setAnchorElNav(null);
    };
  
    const navigateToPage = (page) => {
        setOpen(false);
        document.getElementById(page).scrollIntoView();
        handleCloseNavMenu();
        //navigate("#" + page);
    }

    const logo = 
    <Grid item xs={3} p={5} position="relative" className="logo">
        <Logo     style={{position: "absolute", left: "0px", top: "0px" }}/>
        <LogoText style={{position: "absolute", left: "0px", top: "0px" }} className="text"/>
    </Grid>;

    return (
      <React.Fragment>
      <AppBar position="fixed" sx={{
        background: "rgba(255, 255, 255, 0.4)",
        backdropFilter: "blur(10px)",
        boxShadow: "none"
      }}>
            <Toolbar disableGutters>

            {/* DESKTOP */}
            <Grid container p={8} px={16} justifyContent="space-between" sx={{display: { xs: 'none', md: 'inherit' } }}>
                {/* LOGO */}
                <Grid item xs={3} p={5} position="relative">
                    <Logo     style={{position: "absolute", left: "0px", top: "0px" }}/>
                    <LogoText style={{position: "absolute", left: "0px", top: "0px" }}/>
                </Grid>

                {/* PAGES */}
                <Grid item md={8} lg={6.5} justifyContent="end" display="flex" gap={{xl: 5}}>
                    {pages.map((page) => (
                    <Button
                        variant = "filled"
                        color   = "primary"
                        onClick = {() => navigateToPage(page.handle)}
                        sx      = {{ my: 2, display: 'block' }}
                    > 
                        <Text variant="navigation" fontWeight="500">{page.title}</Text> 
                    </Button>
                    ))}
                    <Button variant="contained" color="dark" sx={{padding: "16px 24px", textWrap: "nowrap", marginLeft: "15px"}} onClick={() => navigateToPage("contact")}>
                        <FormattedMessage id="Project-Button"/>
                    </Button>
                </Grid>
            </Grid>

            {/* MOBILE */}
            <Grid container px={2} justifyContent="space-between" sx={{ display: { xs: 'flex', md: 'none' } }}>
                {logo}

                {/* HAMBURGER */}
                <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={mobileOpen? handleCloseNavMenu : handleOpenNavMenu}
                color="inherit"
                >
                    {mobileOpen? <CloseIcon/> : <MenuIcon />}
                </IconButton>

                {/* PAGES */}
                {/* <Menu
                className="navmenu-mobile"
                keepMounted
                id              = "menu-appbar"
                anchorEl        = {anchorElNav}
                open            = {Boolean(anchorElNav)}
                onClose         = {handleCloseNavMenu}
                anchorOrigin    = {{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin = {{ vertical: 'top', horizontal: 'right' }}
                >
                {pages.map((page) => (
                    <MenuItem key={page} onClick={() => navigateToPage(page.handle)}>
                        <Text textAlign="center">{page.title}</Text>
                    </MenuItem>
                ))}
                </Menu> */}
            </Grid>

            </Toolbar>
        </AppBar>

        {/* mobile menu */}
        <Grid container position="fixed" py={26} px={12} sx={{
            display: mobileOpen ? "block" : "none",
            zIndex: 10,
            background: "white",
            left: 0,
            right: 0,
            top: "0",
            bottom: "-95vh"
        }}>
            {/* PAGES */}
            {pages.map((page) => (
                <Text onClick={() => navigateToPage(page.handle)} mb={5} variant="h1" color="black" textAlign="left">{page.title}</Text>
            ))}
            <Button variant="contained" color="secondary" sx={{padding: "16px 24px", ...props.sx}} onClick={() => navigateToPage("contact")}>
                <FormattedMessage id="Consult-Button"/>
            </Button>

            <Grid item mt={10}>
                <a target="_blank" href="https://instagram.com/energyzed_"><Instagram fill="black" style={{marginLeft: "5px", marginRight: "30px", scale: "1.5"}}/></a>
                <a target="_blank" href="https://www.linkedin.com/company/energyzed.io" ><Linkedin fill="black" style={{scale: "1.5"}}/></a>
            </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  
export default Navbar;