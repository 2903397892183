import { Grid, useTheme } from "@mui/material";
import React from "react";
import Text from "../../visuals/Text";

export default (props) => {
    const theme = useTheme();
    return(
        <React.Fragment>
            <Grid bgcolor={theme.palette.dark.main} container px={{xs: 5, lg: 38}} py={11} rowGap={2}>
                <Text color="secondary" variant="body" id="Footer-1" item xs={12} md={10} sx={{display: {xs: "none", md: "block"}}}/>
                <Text color="secondary" variant="body" id="Footer-2" item xs={12} md={2}  sx={{display: {xs: "none", md: "block"}}}/>

                <Text color="secondary" align="center" variant="body" id="Footer-1.1" item xs={12} sx={{display: {xs: "block", md: "none"}}}/>
                <Text color="secondary" align="center" variant="body" id="Footer-1.2" item xs={12} sx={{display: {xs: "block", md: "none"}}}/>

            </Grid>
        </React.Fragment>
    );
}