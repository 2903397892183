import { useEffect } from "react";

export default () => {
    useEffect(() => {
        const fadeDistance = 300;
        let fade_          = 0;
        let togglers       = document.getElementsByClassName("DarkBgToggle");
        let togglePoints   = [];
        getTogglePoints();

        window.addEventListener("resize", (event) => {
            getTogglePoints();
        });
        document.addEventListener("scroll", (event) => {
            var fadePoint = getClosestPoint();
            fade_ = Math.min(1, Math.max(0, fadePoint.distance / fadeDistance * (fadePoint.fadeIn? -1 : 1)));
            localStorage.setItem("fade", fade_);
        });

        function getTogglePoints() {
            togglePoints = [];
            for (let i = 0; i < togglers.length; i++) {
                togglePoints.push(i % 2 == 0 ? 
                    togglers[i].offsetTop 
                    :
                    togglers[i].offsetTop + togglers[i].offsetHeight);
            }
        }

        function getClosestPoint() {
            var dist   = 900000000;
            var distance
            var fadeIn = true;

            for (let i = 0; i < togglePoints.length; i++) {
                var _d  = togglePoints[i] - window.scrollY;
                var __d = Math.abs(_d);
                if(__d < dist) {
                    fadeIn   = i % 2 == 0;
                    dist     = __d;
                    distance = _d;
                }
            }
            return ({distance: distance - window.innerHeight/2, fadeIn: fadeIn});
        }

    });

    return(null);
}