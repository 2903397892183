import { Button, Grid, TextField, useTheme } from "@mui/material";
import React, { useState } from "react";
import Text from "../../visuals/Text";
import { FormattedMessage, useIntl } from "react-intl";
import { validateEmail } from "../../../functions/Regex";

export default (props) => {
    const [response, setResponse] = useState("");
    const [isValid , setValid] = useState(false);
    const theme = useTheme();
    const intl  = useIntl();
    const [inputs, setInputs] = useState({
        firstName: "",
        lastName : "",
        email    : "",
        query    : "",
        message  : ""
    });

    const input = (id, width = 12, handle) => {
        return(
        <Grid item xs={12} md={width} sx={{height: "fit-content !important"}}>
            <TextField 
            onChange={(value) => {
                var temp     = inputs;
                temp[handle] = value.target.value;
                setInputs(temp);
                setValid(valid());
            }}
            color="primary"
            variant="outlined" 
            sx={{
                'fieldset': {
                    borderColor: theme.palette.dark.contrastText
                }
            }}
            fullWidth 
            required
            inputProps={{sx: {color: theme.palette.dark.contrastText} }}
            placeholder={ intl.formatMessage({id: id}) } />
        </Grid>
        );
    }

    function sendMail() {
        if(!isValid) {
            setResponse("Response-Error");
            return;
        }
        fetch('https://api.emailjs.com/api/v1.0/email/send', {
            method: 'POST',
            headers: {
                'Accept'       : 'application/json',
                'Content-Type' : 'application/json',
            },
            body: JSON.stringify({
                service_id     : 'service_ztu7txz',
                template_id    : 'contact_form',
                user_id        : 'tsaCTEmkGUxGP2CMM',
                template_params: {
                    'first_name'   : inputs.firstName,
                    'last_name'    : inputs.lastName,
                    'mail_address' : inputs.email,
                    'query'        : inputs.query,
                    'message'      : inputs.message
                }
            })
        })
        setInputs({
            firstName: "",
            lastName : "",
            email    : "",
            query    : "",
            message  : ""
        });
        setResponse("Response-Success");
    }

    const valid = () => inputs.firstName.length && inputs.lastName.length && (inputs.email.length && validateEmail(inputs.email)) && inputs.query.length && inputs.message.length;

    return(
        <React.Fragment>
        <Grid container px={{xs: 0, md: 15, lg: 40, xl: 60}}>

            {/* paper */}
            <Grid bgcolor={theme.palette.dark.main} container p={20} rowGap={8} position="relative" borderRadius={{xs: "0", md: "16px 16px 16px 0"}}>
                {/* info */}
                <Text variant="h2"   id="Contact-Title" color="dark" item xs={12} bold hl="contrast"/>
                <Text variant="body" id="Contact-Text"  color="dark" item xs={12} mb={10}/>

                {/* form */}
                <Grid container item xs={12} justifyContent="space-between" sx={{height: "fit-content !important"}} rowGap={7} columnGap={1}>
                    {input("Contact-Name", 5.75, "firstName")}
                    {input("Contact-Name2", 5.75, "lastName")}
                    {input("Email", 12, "email")}
                    {input("Contact-Query", 12, "query")}
                    {input("Contact-Message", 12, "message")}

                    {/* submission */}
                    <Text variant="body2" id="Contact-Label" color="dark" item xs={12} mb={2}/>
                    <Grid item xs={12}>
                        <Button 
                        onClick={() => sendMail()}
                        variant={isValid? "contained" : "outlined"} color="primary" fullWidth sx={{height: "100%", padding: "16px 24px"}}>
                            <FormattedMessage id="Contact-Button"/>
                        </Button>
                    </Grid>
                    {!response ? null : <Text variant="body2" id={response} color="dark" item xs={12}/>}
                    <Text variant="body2" id="Contact-Disclaimer" color="dark" item xs={12}/>
                </Grid>
            
            </Grid>

            {/* little slanted shape */}
            <div className="contact-slope" style={{
                borderRadius: "0 0 0 16px",
                marginTop: "-2px",
                width: "97.5%",
                height: "100px",
                background: theme.palette.dark.main,
                clipPath: "polygon(0% 0%, 100% 2px, 0% 100px)"
            }}></div>
        </Grid>
        </React.Fragment>
    );
}