function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
}

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
  
export default (colorA, colorB, intval) => {
    const rgbA = hexToRgb(colorA),
          rgbB = hexToRgb(colorB);
    const colorVal = (prop) => Math.round(rgbA[prop] * (1 - intval) + rgbB[prop] * intval);
    return rgbToHex(colorVal('r'), colorVal('g'), colorVal('b'));
}
  