//This is the new theme as of december 2023. Try to refer to this theme in as many places as possible.
//replace implementations of src/style/containers/theme.js
//note that for this theme to apply we should use @mui not @material-ui/core
import { createTheme } from '@mui/material/styles';
import ColorInterpolator from '../functions/ColorInterpolator';

export default (fade) => {
    return createTheme({
        spacing: 4,
        typography: {
            fontFamily: 'Poppins',
            h1: {
                fontSize  : "81.871px",
                lineHeight: "normal",
                fontStyle : "normal",
                fontWeight: 400,
                '@media (max-width:1600px)': {
                    fontSize: '3.5rem',
                },
                '@media (max-width:720px)': {
                    fontSize: '3rem',
                }
            },
            h2: {
                fontSize  : "54px",
                lineHeight: "74px",
                fontStyle : "normal",
                '@media (max-width:1600px)': {
                    fontSize: '2.5rem',
                    lineHeight: "3rem",
                },
                '@media (max-width:720px)': {
                    fontSize: '2rem',
                    lineHeight: "2.5rem",
                }
            },
            h3: {
                fontFamily: 'Inter',
                fontSize  : "42px",
                lineHeight: "64px",
                fontStyle : "normal",
                '@media (max-width:1600px)': {
                    fontSize: '2rem',
                    lineHeight: "2.5rem",
                },
                '@media (max-width:720px)': {
                    fontSize: '1.5rem',
                    lineHeight: "2rem",
                }
            },
            h4: {
                fontFamily: 'Inter',
                fontSize  : "36px",
                lineHeight: "36px",
                fontStyle : "normal",
                '@media (max-width:1600px)': {
                    fontSize: '1.5rem',
                    lineHeight: "2rem",
                },
                '@media (max-width:720px)': {
                    fontSize: '1.25rem',
                }
            },
            h5: {
                fontFamily: 'Inter',
                fontSize  : "32px",
                lineHeight: "42px",
                fontStyle : "normal",
                '@media (max-width:1890px)': {
                    fontSize: '1rem',
                    lineHeight: "2rem",
                }
            },
            body: {
                fontFamily: 'Inter',
                fontSize  : "24px",
                lineHeight: "42px",
                fontStyle : "normal",
                '@media (max-width:1600px)': {
                    fontSize: '1.25rem',
                    lineHeight: "1.75rem",
                }
            },
            body2: {
                fontFamily: 'Inter',
                fontSize  : "14px",
                lineHeight: "22px",
                fontStyle : "normal",
                '@media (max-width:1600px)': {
                    fontSize: '0.75rem',
                    lineHeight: "1.25rem",
                }
            },
            navigation: {
                fontFamily: 'Inter',
                fontSize  : "18px",
                lineHeight: "26px"
            },
            button: {
                textTransform: 'none',
                fontFamily: 'Inter',
                fontSize: "18px",
                lineHeight: "26px",
                fontWeight: "500"
            },

            fontWeightRegular: 400,
            fontWeightBold   : 500,
        },
        palette: {
            primary: {
                //dark      : "#00000005",
                main      : ColorInterpolator("#FFFFFF", "#1C1C1C", fade ?? 0),
                light     : "#FFFFFF05",
                contrastText: ColorInterpolator("#1C1C1C", "#FFFFFF", fade ?? 0),
            },
            secondary: {
                main      : "#CC48A7",
                contrastText: "white"
            },
            contrast: {
                main      : "#48CC96",
                contrastText: "white"
            },
            dark: {
                dark      : ColorInterpolator("#000000", "#b3b3b3", fade ?? 0),
                main      : ColorInterpolator("#1C1C1C", "#FFFFFF", fade ?? 0),
                light     : "#1C1C1C",
                contrastText: ColorInterpolator("#FFFFFF", "#1C1C1C", fade ?? 0),
            },
            faded: {
                main      : "#F0F0F0",
                contrastText: "#585858"
            },
        },
        components: {
        },
        custom: {
        }
    });
}
