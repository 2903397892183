import { Button, FormControl, Grid, Input, TextField, useTheme } from "@mui/material";
import React, { useState } from "react";
import Text from "../../visuals/Text";
import { FormattedMessage, useIntl } from "react-intl";
import { validateEmail } from "../../../functions/Regex";

export default (props) => {
    const [response, setResponse] = useState("");
    const theme = useTheme();
    const intl  = useIntl();
    const [input, setInput] = useState("");

    function sendMail() {
        if(!valid()) {
            setResponse("Response-Error");
            return;
        }
        fetch('https://api.emailjs.com/api/v1.0/email/send', {
            method: 'POST',
            headers: {
                'Accept'       : 'application/json',
                'Content-Type' : 'application/json',
            },
            body: JSON.stringify({
                service_id     : 'service_ztu7txz',
                template_id    : 'mail_list',
                user_id        : 'tsaCTEmkGUxGP2CMM',
                template_params: {
                    'mail_address' : input
                }
            })
        })
        setInput("");
        setResponse("Response-Success");
    }
    const valid = () => input.length && validateEmail(input);

    return(
        <React.Fragment>
            <Grid bgcolor="rgba(255, 255, 255, 0.5)" container px={{xs: 15, md: 38}} py={{xs: 15, md: 28}} rowGap={5}>
                {/* info */}
                <Grid container item xs={12} md={6.5} pr={{xs: 0, md: 20}}>
                    <Text variant="h5"   id="MailList-Title" item xs={12} mb={4} bold sx={{display: {xs: "none"  , md: "block"}}}/>
                    <Text variant="h2"   id="MailList-Title" item xs={12} mb={4} bold sx={{display: {xs: "block" , md: "none"}}}/>
                    <Text variant="body" id="MailList-Text"  item xs={12}/>
                </Grid>

                {/* form */}
                <Grid container item xs={12} md={5.5} px={{xs: 0, md: 10}}>
                    <Grid container item xs={12} justifyContent="space-between" sx={{height: "fit-content !important"}}>
                        <Grid item xs={12} md={8} sx={{height: "fit-content !important"}}>
                            <FormControl variant="outlined" defaultValue="" required fullWidth sx={{height: "fit-content !important"}}>
                                <TextField onChange={(value) =>
                                    setInput(value.target.value)
                                } fullWidth placeholder={ intl.formatMessage({id: "Email"}) } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3.5} mt={{xs: 4, md: 0}}>
                            <Button onClick={sendMail} variant={valid()? "contained" : "outlined"} color="dark" fullWidth sx={{height: "100%"}}><FormattedMessage id="MailList-Submit"/></Button>
                        </Grid>
                        {!response ? null : <Text variant="body2" id={response} item xs={12} mt={2}/>}

                        <Text variant="body2" id="MailList-Label" align="left"   item xs={12} mt={2} sx={{display: {xs: "none" , md: "block"}}}/>
                        <Text variant="body2" id="MailList-Label" align="center" item xs={12} mt={2} sx={{display: {xs: "block", md: "none"}}}/>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}