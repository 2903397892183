import { Typography, useTheme } from "@mui/material";

import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";


export default function Text(props) {
    const {children} = props;
    const theme    = useTheme();
    const color    = getColorContrast(props.color);
    const weight   = 
        props.fontWeight ? props.fontWeight :
        props.bold       ? theme.typography.fontWeightBold + " !important" : null;

    function getColorContrast(color){
        return(color ? 
        (theme.palette[color] ? theme.palette[color].contrastText : color) 
        : 
        theme.palette.primary.contrastText);
    }

    const [highlight, setHighlight] = useState(null);
    const fallback = {hl: (...chunks) => <span className="secondary">{chunks}</span>};
    useEffect(() => {
        if(props.hl != null && highlight == null) {
            setHighlight({hl: (...chunks) => <span className={props.hl}>{chunks}</span>});
        }
    }, []);

    return(
        <Grid {...props}>
        <Typography {...props} sx={{fontWeight: weight}} color={color}>
          {props.id ? (<FormattedMessage id={props.id} values={{...(highlight ?? fallback), ...props.values}}/>) : children}
        </Typography>
        </Grid>);
}