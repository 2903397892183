import { Grid } from "@mui/material";
import React from "react";
import Text from "../../visuals/Text";
import {ReactComponent as Finance} from '../../visuals/industries/Finance.svg';
import {ReactComponent as Retail } from '../../visuals/industries/Retail.svg';
import {ReactComponent as Food   } from '../../visuals/industries/Food.svg';
import {ReactComponent as Leisure} from '../../visuals/industries/Leisure.svg';

const industries = [
    {src: <Finance className="hover-anim"/>, id: "Industries-Finance"},
    {src: <Retail  className="hover-anim"/>, id: "Industries-Retail" },
    {src: <Food    className="hover-anim"/>, id: "Industries-Food"   },
    {src: <Leisure className="hover-anim"/>, id: "Industries-Leisure"}];

function Industry(props) {
    return(
        <Grid item {...props} justifyContent="center">
            <Grid container justifyContent="center">
            {props.image}
            </Grid>
            <Text variant="h4" align="center" item xs={12} id={props.id} mt={8} fontWeight={500}/>
        </Grid>
    );
}

export default (props) => {
    return(
        <React.Fragment>
            <Grid container justifyContent="center" my={20} gap={{xs: 8, md: 16}}>
                <Text hl="contrast" variant="h2" item xs={12} align="center" id="Industries-Text" bold px={{xs: 5, md: 0}}/>
                <Grid container item xs={12} justifyContent="space-between" gap={0}>
                    {industries.map((industry) => 
                    <Industry xs={12} md={3} image={industry.src} id={industry.id}/>)}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}